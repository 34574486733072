<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="标题"
            v-model="filterName"
            clearable
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="name" align="center" label="动态标题" />
        <el-table-column prop="companyName" align="center" label="发布人" />
        <el-table-column prop="createTime" align="center" label="发布时间" />
        <el-table-column align="center" label="置顶" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="zhiding(scope.row)"
                v-model="scope.row.assIsTop"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column  align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.cpmpanyStatus"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="viewDetails(scope.row)"
            >查看详情</el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.dynamicId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {queryCompanyDynamicPage,updateCompanyDynamicById,delCompanyDynamicById} from "@/api/square";

export default {
  name: "enterpriseDynamic",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate:1,
      circleDailyId:""
    };
  },
  mounted(){
    this.queryPage();
  },
  methods: {
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryCompanyDynamicPage(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
          list[i].cpmpanyStatus = list[i].cpmpanyStatus==0?true:false
          list[i].assIsTop = list[i].assIsTop==0?true:false
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    viewDetails(row){
      this.$router.push({
        path: `/dynamicDetails`,
        query: { dynamicId: row.dynamicId },
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delCompanyDynamicById({ dynamicId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        name:this.filterName
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    async zhiding(row){
      if(row){
        let ajax = {
          assIsTop:row.assIsTop?0:1,
          dynamicId:row.dynamicId
        }
        const result = await updateCompanyDynamicById(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }
    },
    async saveStatus(row){
      if(row){
        let ajax = {
          cpmpanyStatus:row.cpmpanyStatus?0:1,
          dynamicId:row.dynamicId
        }
        const result = await updateCompanyDynamicById(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>



