<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="日常圈子" name="first">
        <circleList v-if="activeName == 'first'"  :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="招商引资" name="second" v-if="associationId != 51">
        <merchantList v-if="activeName == 'second'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="人脉对接" name="third" v-if="associationId != 51">
        <handleList v-if="activeName == 'third'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="企业动态" name="fourth">
        <enterpriseDynamic v-if="activeName == 'fourth'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import circleList from "./components/circleList.vue";
import merchantList from "./components/merchantList.vue";
import handleList from "./components/handleList.vue";
import enterpriseDynamic from "./components/enterpriseDynamic.vue";
import { mapState } from 'vuex';

export default {
  name: "dynamicList",
  components: {
    circleList,
    merchantList,
    handleList,
    enterpriseDynamic
  },

  data() {
    return {
      associationId: localStorage.getItem("associationId"),
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),

  },
  created(){
    if(this.$route.query.name){
      this.activeName = this.$route.query.name
    }
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("040")
      console.log(this.jurisdiction,'this.jurisdiction')
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>



